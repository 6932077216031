<template>
  <v-main>
    <h2 class="mt-2 mb-8 titleColor--text">Relatórios</h2>
    <dynamic-report-widget class="mt-5" @report-requested="update_table_status_relatorios" />
    <table-status-relatorios ref="tableStatus" class="mt-5" />
  </v-main>
</template>

<script>
export default {
  name: 'Relatorios',
  components: {
    dynamicReportWidget: () => import('./components/DynamicReportWidget.vue'),
    TableStatusRelatorios: () => import('./components/TableStatusRelatorios.vue'),
  },

  methods: {
    update_table_status_relatorios() {
      this.$refs.tableStatus.getAll();
    }
  }
};
</script>

<style scoped></style>
