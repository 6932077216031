var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    [
      _c("h2", { staticClass: "mt-2 mb-8 titleColor--text" }, [
        _vm._v("Relatórios"),
      ]),
      _c("dynamic-report-widget", {
        staticClass: "mt-5",
        on: { "report-requested": _vm.update_table_status_relatorios },
      }),
      _c("table-status-relatorios", {
        ref: "tableStatus",
        staticClass: "mt-5",
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }